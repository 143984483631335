import React from "react"
import Typography from '@material-ui/core/Typography'
import ExpansionPanel from '@material-ui/core/ExpansionPanel'
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary'
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails'
import { makeStyles } from '@material-ui/core/styles'
import ArrowBlueRight from '../../static/programm/arrowBlueRight.svg'
import ArrowBlueDown from '../../static/programm/arrowBlueDown.svg'
import { Divider } from "@material-ui/core"

const useStyles = makeStyles((theme) => ({
  root: {
    boxShadow: '0px 10px 30px rgba(0,0,0,0.10)',
    background: 'white',
    padding: '10px 40px',
    borderRadius: '30px',
    marginBottom: '25px',
    position: 'relative',
    zIndex: '5',
    '@media (max-width:767px)': {
      padding: '10px 25px',
    },
  },
  heading: {
    fontSize: '1.5vw',
    fontWeight: '700',
    width: '35vw',
    '@media (max-width:1024px)': {
      fontSize: '2vw',
    },
    '@media (max-width:767px)': {
      fontSize: '4.6vw',
      width: '100%',
      marginBottom: 30,
    },
  },
  subheading: {
    fontSize: '1.2vw',
    color: '#001224',
    fontWeight: '400',
    fontFamily: 'Roboto',
    marginTop: '0.3vw',
    width: '35vw',
    '@media (max-width:1024px)': {
      fontSize: '1.3vw',
      marginTop: '1vw',
    },
    '@media (max-width:767px)': {
      display: 'none',
    },
  },
  trek: {
    width: '8vw',
    fontSize: '0.85em',
    opacity: '0.5',
    lineHeight: 1.3,
    '@media (max-width:1024px)': {
      fontSize: '1.2vw',
      width: '8vw',
      flexShrink: 0,
      marginLeft: 0,
    },
    '@media (max-width:767px)': {
      width: '100%',
      fontSize: '1em',
      marginLeft: 0,
      marginBottom: 15,
    },
  },
  panelDetailsCss: {
    marginBottom: '30px',
    display: 'block',
    padding: 0,
    width: '100%',
    '@media (max-width:767px)': {
      width: '100%',
    },
  },
  nopadding: {
    padding: '0 !important',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  buttonPanel: {
    fontSize: '3vw',
    fontWeight: '700',
    opacity: '1',
    color: 'rgb(55, 224, 167)',
    '@media (max-width:767px)': {
      fontSize: '8vw',
    },
  },
  panelDiv: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    '@media (max-width:767px)': {
      display: 'block',
    },
  },
  chipsDiv: {
    display: 'flex',
    marginRight: '3vw',
  },
  numberProgram: {
    background: '#E7F0F9',
    borderRadius: 10,
    padding: '9px 15px',
    marginRight: '1vw',
    '@media (max-width:1024px)': {
      fontSize: '1.1vw',
      padding: '0.8vw 1.6vw',
      marginRight: '1vw',
    },
    '@media (max-width:767px)': {
      fontSize: '3vw',
      marginRight: '2vw',
      padding: '1.5vw 3vw',
    },
  },
  time: {
    background: '#E1FFED',
    borderRadius: 10,
    padding: '9px 15px',
    '@media (max-width:1024px)': {
      fontSize: '1.1vw',
      padding: '0.8vw 1.6vw',
    },
    '@media (max-width:767px)': {
      fontSize: '3vw',
      marginRight: '0',
      padding: '1.5vw 3vw',
    },
  },
  Arrow: {
    width: '70px',
    padding: 0,
    '@media (max-width:1024px)': {
      width: '50px',
    },
    '@media (max-width:767px)': {
      width: '50px',
    },
  },
  divider: {
    marginTop: 40,
  },
}))

export default function Programm(props) {

  const classes = useStyles();

  const [open, setOpen] = React.useState(false);

  const all_n = Math.abs(props.speeches_length) % 100
  const all_n1 = all_n % 10

  const ifStateAll = (all_n > 10 && all_n < 20) ? 'й' : ((all_n1 > 1 && all_n1 < 5) ? 'я' : ((all_n1 !== 1) ? null : 'е'))

  const word_speeches = `выступлени${ifStateAll}`

  console.log(props.date_start, 'datestart')
  console.log(props.date_end, 'dateend')

  return(
    <div className={classes.root}>
      <ExpansionPanel elevation={0}>

        <ExpansionPanelSummary
          className={classes.nopadding}
          expandIcon={open ? <div className={classes.Arrow}><ArrowBlueDown /></div> : <div className={classes.Arrow}><ArrowBlueRight /></div>}
          aria-controls="panel1a-content"
          id="panel1a-header"
          onClick={() => setOpen(!open)}>

            <div className={classes.panelDiv}>
              <Typography variant='subtitle2' className={classes.trek}>
                {props.type}
              </Typography>
              <div>
                <Typography variant='h4' className={classes.heading}>
                  {props.title}
                </Typography>
                <Typography variant='subtitle1' className={classes.subheading}>
                  {props.subtitle}
                </Typography>
              </div>
              <div className={classes.chipsDiv}>
                <Typography variant='subtitle2'>
                  <span className={classes.numberProgram}>
                    {props.speeches_length} {word_speeches}
                  </span>
                </Typography>
                <Typography variant='subtitle2'>
                  <span className={classes.time}>
                    {props.ifDate === "date" ? props.date_start : props.time_start}–{props.ifDate === "date" ? props.date_end : props.time_end}
                  </span>
                </Typography>
              </div>
            </div>

        </ExpansionPanelSummary>

        <ExpansionPanelDetails className={classes.panelDetailsCss}>
          <Divider className={classes.divider} />
          {props.children}
        </ExpansionPanelDetails>

      </ExpansionPanel>
    </div>
  )
}
