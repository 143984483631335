import React from "react"
import {graphql, useStaticQuery} from 'gatsby'
import Panel from './panel'
import { Date } from 'prismic-reactjs'
import { format } from 'date-fns-tz'
import Speech from "./speech"

export default function Programm() {

  const query = useStaticQuery(graphql`
    query program_without {
      allPrismicProgram {
        edges {
          node {
            data {
              type
              title {
                text
              }
              subtitle
              speeches {
                speech {
                  uid
                }
              }
            }
          }
        }
      }
      allPrismicSpeech(sort: {fields: data___time_start}) {
        edges {
          node {
            data {
              time_end
              time_start
              title {
                text
              }
              speaker {
                uid
              }
            }
            uid
          }
        }
      }
      allPrismicSpeaker {
        edges {
          node {
            data {
              speaker_name {
                text
              }
              speaker_photo {
                alt
                localFile {
                  childImageSharp {
                    fluid {
                      src
                      srcWebp
                    }
                  }
                }
              }
            }
            uid
          }
        }
      }
    }

  `)

  const program = query.allPrismicProgram.edges
  const speech = query.allPrismicSpeech.edges
  const speaker = query.allPrismicSpeaker.edges

  console.log(speech.filter(atr => program[2].node.data.speeches.map(item => item.speech.uid).includes(atr.node.uid))[speech.filter(atr => program[2].node.data.speeches.map(item => item.speech.uid).includes(atr.node.uid)).length - 1], 'date')

  const ifDate = speech.map(item => format(Date(item.node.data.time_start), 'dd')).every(item => item === format(Date(speech[0].node.data.time_start), 'dd')) ? 'time' : 'date'

  return(
    <div>
      {program.sort(function(x, y){
          return Date(speech.filter(atr => atr.node.uid === x.node.data.speeches[0].speech.uid)[0].node.data.time_start) - Date(speech.filter(atr => atr.node.uid === y.node.data.speeches[0].speech.uid)[0].node.data.time_start);
      }).map((content, index) => (
        <Panel
          key={'program' + index}
          type={content.node.data.type}
          title={content.node.data.title.text}
          subtitle={content.node.data.subtitle}
          speeches_length={content.node.data.speeches.length}
          ifDate={ifDate}
          date_start={format(Date(speech.filter(atr => content.node.data.speeches.map(item => item.speech.uid).includes(atr.node.uid))[0].node.data.time_start),'dd.MM')}
          date_end={format(Date(speech.filter(atr => content.node.data.speeches.map(item => item.speech.uid).includes(atr.node.uid))[speech.filter(atr => content.node.data.speeches.map(item => item.speech.uid).includes(atr.node.uid)).length - 1].node.data.time_end),'dd.MM')}
          time_start={format(Date(speech.filter(atr => content.node.data.speeches.map(item => item.speech.uid).includes(atr.node.uid))[0].node.data.time_start),'H:mm')}
          time_end={format(Date(speech.filter(atr => content.node.data.speeches.map(item => item.speech.uid).includes(atr.node.uid))[speech.filter(atr => content.node.data.speeches.map(item => item.speech.uid).includes(atr.node.uid)).length - 1].node.data.time_end),'H:mm')}
          >
          {speech.filter(
            atr => content.node.data.speeches.map(item => item.speech.uid).includes(atr.node.uid)
          ).map((content, index) => (
            <Speech
              content={content}
              speaker={speaker}
              key={'speech' + index}
            />
          ))}
        </Panel>
      ))}
    </div>
  )
}
