import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Date } from 'prismic-reactjs'
import { format } from 'date-fns-tz'
import { Typography } from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        boxSizing: 'border-box',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        marginTop: 50,
        '&:nth-child(odd)': {
            '& #speakerName': {
                background: '#00DF68',
            },
        },
        '@media (max-width:767px)': {
            justifyContent: 'flex-start',
            alignItems: 'start',
            flexWrap: 'wrap',
            marginTop: '6vw',
            padding: '4vw',
            background: 'rgba(231, 240, 249, 0.4)',
            borderRadius: 'calc(13px + 4vw)',
            border: '2px solid rgba(231, 240, 249, 0.8)',
        },
    },
    time: {
        '@media (max-width:767px)': {
            display: 'flex',
            width: 'calc(100vw - 10vw - 50px - 8vw - 4px)',
            flexShrink: 0,
            marginBottom: '3vw',
            '&:nth-child(1)': {
                justifyContent: 'flex-end',
                marginBottom: '2vw',
            },
        },
    },
    date: {
        lineHeight: 1,
        fontSize: '1.2vw',
        fontWeight: 700,
        display: 'flex',
        '@media (max-width:1024px)': {
        },
        '@media (max-width:767px)': {
            fontSize: '4vw',
        },
    },
    timeStart: {
        padding: '11px 17px',
        borderRadius: 13,
        marginBottom: 15,
        background: '#E1FFED',
        lineHeight: 1,
        fontSize: '1.2vw',
        '@media (max-width:1024px)': {
            marginBottom: '1vw',
        },
        '@media (max-width:767px)': {
            fontSize: '3vw',
            marginBottom: 0,
            marginRight: '2vw',
        },
    },
    timeEnd: {
        padding: '11px 17px',
        borderRadius: 13,
        background: '#FFEBEB',
        lineHeight: 1,
        fontSize: '1.2vw',
        '@media (max-width:767px)': {
            fontSize: '3vw',
        },
    },
    title: {
        display: 'flex',
        width: '32vw',
        fontWeight: 400,
        lineHeight: 1.3,
        fontSize: '1.3vw',
        '@media (max-width:1024px)': {
            fontSize: '1.4vw',
        },
        '@media (max-width:767px)': {
            width: 'calc(100vw - 10vw - 50px - 8vw - 4px)',
            flexShrink: 0,
            marginBottom: props => props.avatar !== undefined ? '8vw' : 0,
            fontSize: '4vw',
        },
    },
    img: {
        width: '100%',
        height: '100%',
        objectFit: 'cover',
    },
    speakerAvatar: {
        width: 90,
        height: 90,
        borderRadius: 100,
        overflow: 'hidden',
        '@media (max-width:1024px)': {
            width: '7vw',
            height: '7vw',
        },
        '@media (max-width:767px)': {
            width: '15vw',
            height: '15vw',
            marginRight: '2vw',
            display: props => props.avatar !== undefined ? 'block' : 'none',
        },
    },
    wrapper: {
        width: '20vw',
        display: 'flex',
        '@media (max-width:767px)': {
            height: '15vw',
            alignItems: 'center',
            width: 'auto',
            display: props => props.name !== undefined ? 'flex' : 'none',
        },
    },
    speakerName: {
        padding: '11px 17px',
        fontWeight: 700,
        fontSize: '1.2vw',
        color: 'white',
        background: '#0047FF',
        borderRadius: 13,
        lineHeight: 1.3,
        '@media (max-width:1024px)': {
            fontSize: '1.4vw',
        },
        '@media (max-width:767px)': {
            fontSize: '3vw',
        },
    },
}))

export default function Speech(props) {

    const date_start = format(Date(props.content.node.data.time_start),'dd.MM')
    const time_start = format(Date(props.content.node.data.time_start),'H:mm')
    const time_end = format(Date(props.content.node.data.time_end),'H:mm')

    const avatar = props.speaker.filter(atr => atr.node.uid === props.content.node.data.speaker.uid)[0]?.node.data.speaker_photo.localFile.childImageSharp.fluid
    const name = props.speaker.filter(atr => atr.node.uid === props.content.node.data.speaker.uid)[0]?.node.data.speaker_name.text
    const classes = useStyles({name, avatar})
    
    return (
        <div className={classes.root}>
            <div className={classes.time}>
                <div className={classes.date}>{date_start}</div>
            </div>
            <div className={classes.time}>
                <div className={classes.timeStart}>{time_start}</div>
                <div className={classes.timeEnd}>{time_end}</div>
            </div>
            <Typography variant="h3" className={classes.title}>{props.content.node.data.title.text}</Typography>
            <div className={classes.speakerAvatar}>
                {avatar !== undefined
                ?
                <picture>
                    <source
                        className={classes.img}
                        srcSet={avatar.src}
                        type="image/webp"
                    />
                    <img
                        className={classes.img}
                        src={avatar.srcWebp}
                        alt={`${props.speaker.filter(atr => atr.node.uid === props.content.node.data.speaker.uid)[0]?.node.data.speaker_photo.alt}`}
                    />
                </picture>
                :
                null
                }
            </div>
            <div className={classes.wrapper}>
                {name !== undefined
                ?
                <div id="speakerName" className={classes.speakerName}>{name}</div>
                :
                null}
            </div>
        </div>
    )
}